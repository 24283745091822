import { EnumNames } from '@/models/types'

export enum PaymentStatus {
  Unpaid = 1,
  Paid,
  Refund,
  Bounce,
  Cancelled,
}

export const paymentStatuses: EnumNames = {
  [PaymentStatus.Unpaid]: 'Unpaid',
  [PaymentStatus.Paid]: 'Paid',
  [PaymentStatus.Refund]: 'Refund',
  [PaymentStatus.Bounce]: 'Bounce',
  [PaymentStatus.Cancelled]: 'Cancelled',
}

export enum PaymentInvoiceStatus {
  Unpaid = 1,
  Paid,
  Refund,
}

export const paymentInvoiceStatuses: EnumNames = {
  [PaymentInvoiceStatus.Unpaid]: 'Unpaid',
  [PaymentInvoiceStatus.Paid]: 'Paid',
  [PaymentInvoiceStatus.Refund]: 'Refund',
}

export enum CompanyType {
  DVBLAB = 'D',
  FORT = 'F',
}

export const companyTypes: EnumNames = {
  [CompanyType.DVBLAB]: 'DVBLAB',
  [CompanyType.FORT]: 'FORT',
}

export enum CurrencyType {
  Euro = 1,
}

export const currencyTypes: EnumNames = {
  [CurrencyType.Euro]: 'Euro',
}

export enum PaymentMethodType {
  SEPA = 1,
  Card = 2,
  Cash = 3,
  BankTransfer = 4,
  Partner = 5,
  Online = 6,
}

export const personPaymentMethodTypes: EnumNames = {
  [PaymentMethodType.SEPA]: 'SEPA',
  [PaymentMethodType.Card]: 'Card',
  [PaymentMethodType.Cash]: 'Cash',
  [PaymentMethodType.BankTransfer]: 'BankTransfer',
  [PaymentMethodType.Online]: 'Online',
}

export const allPaymentMethodTypes: EnumNames = {
  ...personPaymentMethodTypes,
  [PaymentMethodType.Partner]: 'Partner',
}

export enum SepaDocumentType {
  XML = 'xml',
  CSV = 'csv',
}

export const sepaDocumentTypes: SepaDocumentType[] = [SepaDocumentType.XML, SepaDocumentType.CSV]

export enum InformerType {
  Unknown = '',
  Error = 'error',
  Done = 'done',
  Continues = 'continues',
}

export const informerTypes: EnumNames = {
  [InformerType.Error]: 'Error',
  [InformerType.Done]: 'Done',
  [InformerType.Continues]: 'Continues',
}
