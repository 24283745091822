import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_CORE } from '@/permissions/core'
import { ROUTER_PARAM_ID, ROUTER_PARAM_PERSON_ID } from '@/utils/constants'
import { CAN_GENERATE_SEPA } from '@/permissions/core_server'
import { parseBoolean } from '@/utils/strings'

export const ROUTE_GENERATE_SEPA_CSV = 'ROUTE_GENERATE_SEPA_CSV'

export const ROUTE_SUBSCRIPTIONS = 'ROUTE_SUBSCRIPTIONS'
export const ROUTE_SUBSCRIPTION_VIEW = 'ROUTE_SUBSCRIPTION_VIEW'
export const ROUTE_SUBSCRIPTION_ORPHANS = 'ROUTE_SUBSCRIPTION_ORPHANS'

export const ROUTE_INSTALLATIONS = 'ROUTE_INSTALLATIONS'
export const ROUTE_INSTALLATION_VIEW = 'ROUTE_INSTALLATION_VIEW'

export const ROUTE_INSTALLATION_QUICK_FORM = 'ROUTE_INSTALLATION_QUICK_FORM'
export const ROUTE_INSTALLATION_QUICK_FORM_FOR_PERSON = 'ROUTE_INSTALLATION_QUICK_FORM_FOR_PERSON'
export const ROUTE_INSTALLATION_EDIT_EQUIPMENTS = 'ROUTE_INSTALLATION_EDIT_EQUIPMENTS'

export const ROUTE_ADDITIONAL_CHARGES = 'ROUTE_ADDITIONAL_CHARGES'
export const ROUTE_ADDITIONAL_CHARGES_EDIT = 'ROUTE_ADDITIONAL_CHARGES_EDIT'
export const ROUTE_ADDITIONAL_CHARGES_LOAD = 'ROUTE_ADDITIONAL_CHARGES_LOAD'
export const ROUTE_ADDITIONAL_LANDLINE_CHARGES_LOAD = 'ROUTE_ADDITIONAL_LANDLINE_CHARGES_LOAD'

export const ROUTE_PARTNER_FIBRE = 'ROUTE_PARTNER_FIBRE'

const subscriptionRoutes: RouteConfig[] = [
  {
    name: ROUTE_SUBSCRIPTIONS,
    path: '/subscriptions',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/SubscriptionList.vue'
      ),
  },
  {
    name: ROUTE_SUBSCRIPTION_VIEW,
    path: `/subscriptions/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/SubscriptionView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_SUBSCRIPTION_ORPHANS,
    path: `/subscriptions/orphans`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/SubscriptionList.vue'
      ),
    props: () => ({ isOrphans: true }),
  },

  {
    name: ROUTE_ADDITIONAL_CHARGES,
    path: '/subscriptions/additional-charges',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/AdditionalChargeList.vue'
      ),
  },
  {
    name: ROUTE_ADDITIONAL_CHARGES_EDIT,
    path: `/subscriptions/additional-charges/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/AdditionalChargeEdit.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_ADDITIONAL_CHARGES_LOAD,
    path: `/subscriptions/mobile-charges-load`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/LoadMobileCharges.vue'
      ),
  },
  {
    name: ROUTE_ADDITIONAL_LANDLINE_CHARGES_LOAD,
    path: `/subscriptions/landline-charges-load`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/LoadLandlineCharges.vue'
      ),
  },

  {
    name: ROUTE_INSTALLATION_QUICK_FORM,
    path: `/quick-form/:${ROUTER_PARAM_ID}(\\d+)?`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/quick_install/QuickInstall.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_INSTALLATION_QUICK_FORM_FOR_PERSON,
    path: `/quick-form-for-person/:${ROUTER_PARAM_PERSON_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/quick_install/QuickInstall.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_PERSON_ID]: Number(route.params[ROUTER_PARAM_PERSON_ID]),
    }),
  },

  {
    name: ROUTE_INSTALLATION_EDIT_EQUIPMENTS,
    path: `/edit-installation-equipments/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/quick_install/IssuanceEquipment.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
      isForced: parseBoolean(route.params['isForced']),
    }),
  },
  {
    name: ROUTE_INSTALLATIONS,
    path: '/installations',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/InstallationList.vue'
      ),
  },

  {
    name: ROUTE_INSTALLATION_VIEW,
    path: `/installations/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/InstallationView.vue'
      ),
    props: (route) => ({ [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]) }),
  },

  {
    name: ROUTE_GENERATE_SEPA_CSV,
    path: `/generate-sepa-csv`,
    meta: { permission: CAN_GENERATE_SEPA },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/GenerateSepa.vue'
      ),
  },

  {
    name: ROUTE_PARTNER_FIBRE,
    path: `/partner-fibre`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../components/subscriptions/PartnerFibre.vue'
      ),
  },
]

export default subscriptionRoutes
